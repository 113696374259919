import React from 'react';
import {
  Modal,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import { modalController } from './ModalController';
import { responsiveScreenRepository } from '../../../Domain/Repositories/ResponsiveScreenRepository';
import { Colors } from '../../../Config/Colors';

/**
 * Modal Controller Props.
 */
export interface ModalViewBaseProps
{
  /**
   * The modal type.
   */
  modalType: string;

  /**
   * Children components.
   */
  children?: JSX.Element | JSX.Element[];

  /**
   * Callback when the modal is about to be hidden.
   */
  onModalHidden?: () => void;

  /**
   * Callback when the modal is about to be shown.
   */
  onModalShown?: () => void;

  /**
   * Callback when the modal was intentionally closed.
   */
  onModalIntentionallyClosed?: () => void;

  /**
   * The animation type. Defaults to fade.
   */
  animationType?: 'none' | 'slide' | 'fade' | undefined;

  /**
   * Flag to close the model on background touched.
   * Defaults to true.
   */
  closeModalOnBackgroundTouched?: boolean;
}

/**
 * Modal View base.
 */
export default function ModalViewBase({
  modalType = '',
  children,
  onModalHidden,
  onModalShown,
  onModalIntentionallyClosed,
  animationType = 'fade',
  closeModalOnBackgroundTouched = true,
}: ModalViewBaseProps): JSX.Element
{
  const getActiveState = (): boolean =>
  {
    if (modalType === '')
    {
      return false;
    }

    return modalController.activeModal.modalType === modalType;
  };

  const onBackgroundTouched = (): void =>
  {
    // Hide the modal if the background has been touched.
    if (closeModalOnBackgroundTouched)
    {
      modalController.hide();
    }

    if (onModalIntentionallyClosed !== undefined)
    {
      onModalIntentionallyClosed();
    }
  };

  const onShow = (): void =>
  {
    if (onModalShown !== undefined)
    {
      onModalShown();
    }
  };

  return useObserver(() => (
    <Modal
      animationType={animationType}
      visible={getActiveState()}
      onDismiss={onModalHidden}
      transparent
      statusBarTranslucent
      onShow={onShow}
    >
      <View style={[styles.container, {
        width: responsiveScreenRepository.newWidth,
        height: responsiveScreenRepository.newHeight,
      }]}
      >
        <ScrollView
          style={[styles.scrollView, {
            width: responsiveScreenRepository.newWidth,
            height: responsiveScreenRepository.newHeight,
          }]}
          contentContainerStyle={[styles.scrollViewContentContainer, {
            width: responsiveScreenRepository.newWidth,
            height: responsiveScreenRepository.newHeight,
          }]}
        >
          <TouchableOpacity
            activeOpacity={1} // Disable the press opacity
            style={[styles.backgroundExitButton, {
              width: responsiveScreenRepository.newWidth,
              height: responsiveScreenRepository.newHeight,
            }]}
            onPress={onBackgroundTouched}
          />
          {children}
        </ScrollView>
      </View>
    </Modal>
  ));
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollView: {
  },
  scrollViewContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
  },
  backgroundExitButton: {
    backgroundColor: Colors.BLACK(0.5),
    position: 'absolute',
  },
});

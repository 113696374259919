/**
 * The available signalR event types.
 */
enum SignalREventTypes
{
  /**
   * The export status update event name.
   */
   ExportStatusUpdate = "ExportStatusUpdate",
}

export default SignalREventTypes;

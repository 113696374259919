import { LogLevel } from '@microsoft/signalr';

/**
 * The logging level for the signalR service.
 */
export const SIGNALR_LOGLEVEL = LogLevel.Information;

/**
 * The available reconnect time in milliseconds.
 */
export const SIGNALR_RECONNECTION_MILLISECONDS = 5000;

/**
 * Disables the connection to the signalR server.
 */
export const DISABLE_SIGNALR_CONNECTION = false;
import { useHistory } from 'react-router-dom';
import Logger from '../Logger/Logger';

const logger = Logger.Create('useNavigation');

type NavigateToScreenType = (
  screenType: string,
  parameters?: {},
  allowSameScreenNavigation?: boolean,
) => void;

type HandleBrowserBackType = (action: () => void) => void;

type HandleGoBack = () => void;
type GetNavigationHistories = () => string[];

type usePortNavigationType = {
  /**
   * Navigate to a screen with parameters.
   */
  navigateToScreen: NavigateToScreenType;

  /**
   * Navigate to a screen and clears the stack.
   */
  navigateAndClearStack: NavigateToScreenType;

  /**
   * Handle action when the browser back button was pressed.
   */
  handleBrowserBackClicked: HandleBrowserBackType;

  /**
   * Simple go back.
   */
  goBack: HandleGoBack;

  /**
   * Returns the list of navigation histories.
   */
  getNavigationHistories: GetNavigationHistories;
};

const navigationHistory: string[] = [];

/**
 * Hook for handling navigation between the screens
 */
const useNavigation = (): usePortNavigationType =>
{
  const history = useHistory();

  const navigateToScreen: NavigateToScreenType = (
    screenType: string,
    parameters?: {},
    allowSameScreenNavigation = true,
  ) =>
  {
    const navigationPath = `/${screenType}`;

    const lastScreen = navigationHistory[navigationHistory.length - 1];

    if (lastScreen === screenType && !allowSameScreenNavigation)
    {
      logger.trace(`Not navigating to the requested screen: ${screenType} because you are already in this screen!`);
      return;
    }

    navigationHistory.push(screenType);
    history.push({
      pathname: navigationPath,
      state: parameters,
    });
  };

  const navigateAndClearStack: NavigateToScreenType = (screenType: string, parameters?: {}) =>
  {
    const navigationPath = `/${screenType}`;

    navigationHistory.length = 0;
    navigationHistory.push(screenType);

    history.replace({
      pathname: navigationPath,
      state: parameters,
    }, '');
  };

  const handleBrowserBackClicked: HandleBrowserBackType = (action: () => void) =>
  {
    history.listen((_, actionType) =>
    {
      if (actionType === 'POP')
      {
        action();
      }
    });
  };

  const goBack: HandleGoBack = () =>
  {
    navigationHistory.pop();
    const lastScreen = navigationHistory[navigationHistory.length - 1];

    if (lastScreen !== undefined)
    {
      navigateToScreen(lastScreen);
      return;
    }

    history.goBack();
  };

  const getNavigationHistories: GetNavigationHistories = () => navigationHistory;

  return {
    navigateToScreen,
    navigateAndClearStack,
    handleBrowserBackClicked,
    goBack,
    getNavigationHistories,
  };
};

export default useNavigation;

import { useObserver } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../Config/Colors';
import SignalREvent from '../../Domain/Models/SignalREvent';
import AuditDataService from '../../Domain/Services/AuditDataService';
import SignalRService from '../../Domain/Services/SignalRService';
import SignalREventTypes from '../../Domain/Types/SignalREventTypes';
import Logger from '../../Domain/Logger/Logger';
import ExportStatus from '../../Domain/Models/ExportStatus';
import ExportStatusTypes from '../../Domain/Types/ExportStatusTypes';
import Utils from '../../Domain/Utils/Utils';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { sessionTokenRepository } from '../../Domain/Repositories/SessionTokenRepository';
import useNavigation from '../../Domain/Hooks/useNavigation';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import SessionService from '../../Domain/Services/SessionService';
import { Linking } from 'react-native';
import { getEnvironment } from '../../Config/Environment';
import DateTimeFilterModal from '../Shared/Modals/DateTimeFilterModal';
import { responsiveScreenRepository } from '../../Domain/Repositories/ResponsiveScreenRepository';
import LogView from './components/LogView';
import Header from './components/Header';
import TextInputWithLabel from './components/TextInputWithLabel';
import ButtonWithLabel from './components/ButtonWithLabel';
import Footer from './components/Footer';

const logger = Logger.Create('MainScreen');

interface MainProps
{
  /**
   * The maximum logs to keep.
   */
  maxLogs?: number;
}

/**
 * Renders the Main Screen.
 */
export default function Main({
  maxLogs = 100,
}: MainProps): JSX.Element
{
  const { navigateToScreen } = useNavigation();

  const [dateSelectionVisibility, setDateSelectionVisibility] = useState(false);
  const [venue, setVenue] = useState('');
  const [applicationName, setApplicationName] = useState('');
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [loading, setLoading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState<string[]>([]);

  useEffect(() =>
  {  
    const initializeService = async (): Promise<void> =>
    {
      const token = await sessionTokenRepository.getJwtToken();
  
      if (token === '')
      {
        navigateToScreen(ScreenTypes.Login);
        return;
      }
  
      updateLogs('Initialising service.');
      SignalRService.OnSignalRConnectionFailedCallback = onSignalRConnectionFailed;
      SignalRService.RegisterMessageReceivedCallback(onSignalRMessageReceived);
      SignalRService.RegisterOnDisconnectedCallback(onSignalRDisconnected);
      SignalRService.Connect();
      updateLogs('Initialisation complete.');
    };
  
    initializeService();
  
    return () => {
      cleanup();
    };
  }, []);

  const cleanup = (): void =>
  {
    SignalRService.OnSignalRConnectionFailedCallback = undefined;
    SignalRService.UnregisterAllDisconnectedCallbacks();
    SignalRService.UnregisterAllMessageReceivedCallbacks();
  }

  const onSignalRDisconnected = (): void =>
  {
    updateLogs('An existing SignalR connection was terminated!');
  };

  const onSignalRConnectionFailed = (): void =>
  {
    updateLogs('Failed to connect to SignalR!');
  };

  const onSignalRMessageReceived = (signalREvent: SignalREvent): void =>
  {
    if (signalREvent.eventType !== SignalREventTypes.ExportStatusUpdate)
    {
      logger.error(`An unknown signalREvent: ${signalREvent.eventType} was provided, ignoring...`);
      return;
    }

    onExportStatusUpdateReceived(signalREvent.message);
  }

  const updateLogs = (value: string): void =>
  {
    const formattedValue = `[ ${new Date().toLocaleString()} ] - ${value}`;
    setDownloadStatus(prevState => [formattedValue, ...prevState].slice(0, maxLogs));
  };

  const disableExportButton = (): boolean =>
  {
    if (loading || applicationName.trim() === ''
      || fromDate === undefined || toDate === undefined
      || venue.trim() === '')
    {
      return true;
    }

    return false;
  };

  const onExportStatusUpdateReceived = (message: string): void =>
  {
    const data: ExportStatus = JSON.parse(message);

    switch(data.status)
    {
      case ExportStatusTypes.Queued:
        updateLogs("Request queued...");
        setLoading(true);
        break;
      case ExportStatusTypes.InProgress:
        {
          const { statusInfo } = data;
          const message = statusInfo === null ? 'Processing request...' : `Processing request: ${data.statusInfo}`;
          updateLogs(message);
  
          setLoading(true);
          break;
        }
      case ExportStatusTypes.Complete:
        setLoading(false);
        updateLogs('Processing complete. Attempting download.');
        Utils.downloadFile(data.fileName);
        break;
      case ExportStatusTypes.Errored:
        updateLogs(`Processing error: ${JSON.stringify(data)}`);
        setLoading(false);
        break;
    }
  }

  const onExportFileSelected = async (): Promise<void> =>
  {
    const isOnline = navigator.onLine ?? true;

    if (!isOnline)
    {
      updateLogs('You are not connected to the internet!');
      return;
    }

    if (venue.trim() === '')
    {
      updateLogs('No venue provided!');
      return;
    }

    if (applicationName.trim() === '')
    {
      updateLogs('No application type provided!');
      return;
    }

    if (fromDate === undefined || toDate === undefined)
    {
      updateLogs('No date selected!')
      return;
    }

    if (loading)
    {
      updateLogs('Request denied. An existing request is being processed.');
      return;
    }

    updateLogs(`Export requested for ${venue}: ${applicationName} - from ${getDateTimeDisplay()}`)
    setLoading(true);
    
    if (!SignalRService.GetConnectionState())
    {
      updateLogs('Request failed - SignalR is not connected.')
      setLoading(false);
      return;
    }

    const response = await AuditDataService.export(
      venue,
      applicationName,
      fromDate.toISOString(),
      toDate.toISOString(),
    );
    
    setLoading(false);

    if (!response.success)
    {
      updateLogs(response.reason);
    }
  };

  const onAllExportsSelected = (): void =>
  {
    const environment = getEnvironment();
    Linking.openURL(`${environment.apiBase}/exports`);
  };

  const onDateTimeSelected = (from: Date, to: Date): void =>
  {
    setFromDate(from);
    setToDate(to);
  };
  
  /**
   * View the date time as local string.
   * But make the request as ISO.
   */
  const getDateTimeDisplay = (): string =>
  {
    if (fromDate === undefined || toDate === undefined)
    {
      return 'Required Field'
    }

    return `${fromDate.toLocaleString()} to ${toDate.toLocaleString()}`;
  };

  const onLogoutPressed = (): void =>
  {
    SessionService.logout();
    navigateToScreen(ScreenTypes.Login);
  };

  const styles = useMemo(() => StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentContainer: {
      height: RFPercentage(20),
      width: undefined,
      aspectRatio: 3,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.APP.darkBlue,
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(2.5),
        height: RFValue(2.5),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      borderWidth: RFValue(1),
      borderColor: 'white',
      flexDirection: 'row',
    },
    inputSectionContainer: {
      flex: 2,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inputContainer: {
      width: '80%',
      height: '20%',
      alignItems: 'center',
      marginVertical: '2%',
    },
    dateTimeSelectionContainer: {
      width: '80%',
      height: '20%',
      marginVertical: '2%',
    },
    logSectionContainer: {
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
      borderLeftWidth: RFValue(1),
      borderLeftColor: 'white',
    },
    optionsContainer: {
      width: RFPercentage(40),
      height: RFPercentage(5),
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    headerContainer: {
      width: '30%',
      height: RFValue(30),
      top: 0,
      left: 0,
      position: 'absolute',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <View style={[styles.container, {
      width: responsiveScreenRepository.newWidth,
      height: responsiveScreenRepository.newHeight,
      backgroundColor: Colors.APP.darkBlue,
    }]}
    >
      <View style={styles.contentContainer}>
        <View style={styles.inputSectionContainer}>
          <View style={styles.inputContainer}>
            <TextInputWithLabel
              title="Venue"
              value={venue}
              onChangeText={setVenue}
            />
          </View>

          <View style={styles.inputContainer}>
            <TextInputWithLabel
              title="Type"
              value={applicationName}
              onChangeText={setApplicationName}
            />
          </View>

          <View style={styles.dateTimeSelectionContainer}>
            <ButtonWithLabel
              title="Export Dates"
              onPressed={(): void => setDateSelectionVisibility(true)}
              value={getDateTimeDisplay()}
            />
          </View>
        </View>

        <View style={styles.logSectionContainer}>
          <LogView
            logs={downloadStatus}
            onClearLogsPressed={(): void => setDownloadStatus([])}
          />
        </View>
      </View>

      <View style={styles.optionsContainer}>
        <Footer
          disableExportFile={disableExportButton()}
          onExportAllSelected={onAllExportsSelected}
          onExportFileSelected={onExportFileSelected}
        />
      </View>

      <DateTimeFilterModal
        show={dateSelectionVisibility}
        onDateTimeSelected={onDateTimeSelected}
        onExitPressed={(): void => setDateSelectionVisibility(false)}
      />

      <View style={styles.headerContainer}>
        <Header
          onLogoutPressed={onLogoutPressed}
        />
      </View>
    </View>
  ));
}
import Logger from '../Logger/Logger';
import { roleRepository } from '../Repositories/RoleRepository';
import { sessionTokenRepository } from '../Repositories/SessionTokenRepository';
import { userRepository } from '../Repositories/UserRepository';

const logger = Logger.Create('SessionService');

/**
 * Handles the Session operations.
 */
export default class SessionService
{
  /**
   * Handles all logout functionalities.
   */
  public static logout(): void
  {
    logger.info('Clearing repositories...');

    roleRepository.clearAll();
    sessionTokenRepository.clear();
    userRepository.clearAll();

    logger.info('Resetted all the repositories.');
  }
}

import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TextInput,
  Animated,
  Image
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import useNavigation from '../../Domain/Hooks/useNavigation';
import { sessionTokenRepository } from '../../Domain/Repositories/SessionTokenRepository';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import { appRepository } from '../../Domain/Repositories/AppRepository';
import SsoService from '../../Domain/Services/SsoService';
import INotificationModalData from '../../Domain/Models/INotificationModalData';
import { BaseModalType, modalController } from '../Shared/Modals/ModalController';
import ExitButton from '../Shared/Modals/ExitButton';
import Icons from '../Assets/Icons';
import { Colors } from '../../Config/Colors';
import SimpleNotificationModal from '../Shared/Modals/SimpleNotificationModal/SimpleNotificationModal';
import Fonts from '../../Domain/Types/Fonts';
import { useObserver } from 'mobx-react';
import { responsiveScreenRepository } from '../../Domain/Repositories/ResponsiveScreenRepository';

/**
 * Renders the Reset Password Screen.
 * @returns JSX.Element.
 */
export default function ResetPasswordScreen(): JSX.Element
{
  const { navigateToScreen } = useNavigation();

  const [showNotification, setShowNotification] = useState(false);
  const [loginMessage, setLoginMessage] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [valid, setValid] = useState(false);

  useEffect(() =>
  {
    appRepository.dataRetrievalCompleted = {
      didComplete: true,
      failed: false,
    };
  }, []);

  const onSubmitPressed = async (): Promise<void> =>
  {
    const response = await SsoService.changePassword(currentPassword, newPassword);

    if (response.success)
    {
      showSuccessNotification();
      return;
    }

    setLoginMessage(response.reason);
  };

  const showSuccessNotification = (): void =>
  {
    setShowNotification(true);

    const notification: INotificationModalData = {
      title: 'Password Updated',
      message: 'Your password has been changed successfully. Use your new password to login again.',
      onConfirmButtonPressed: (): void => navigateToScreen(ScreenTypes.Login),
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  useEffect(() =>
  {
    setLoginMessage('');

    if (currentPassword.trim() === '' || newPassword.trim() === '' || confirmPassword.trim() === '')
    {
      setLoginMessage('All fields must be filled.');
      setValid(false);
      return;
    }

    if (newPassword.toLowerCase() !== confirmPassword.toLowerCase())
    {
      setLoginMessage('Passwords do not match.');
      setValid(false);
      return;
    }

    if (newPassword.length < 6 || confirmPassword.length < 6)
    {
      setLoginMessage('Passwords must contain at least 6 digits.');
      setValid(false);
      return;
    }

    setValid(true);
  }, [currentPassword, newPassword, confirmPassword]);

  const onCurrentPasswordChanged = (input: string): void =>
  {
    setCurrentPassword(input.replace(/[^0-9]/g, ''));
  };

  const onNewPasswordChanged = (input: string): void =>
  {
    setNewPassword(input.replace(/[^0-9]/g, ''));
  };

  const onConfirmPasswordChanged = (input: string): void =>
  {
    setConfirmPassword(input.replace(/[^0-9]/g, ''));
  };

  const styles = useMemo(() => StyleSheet.create({
    contentContainer: {
      flex: 1,
      width: '100%',
    },
    icon: {
      width: undefined,
      height: RFValue(50),
      aspectRatio: 1.5,
      maxHeight: '50%',
    },
    loginContainer: {
      flex: 1,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginView: {
      height: RFValue(180),
      width: undefined,
      aspectRatio: 1,
      backgroundColor: Colors.APP.darkBlue,
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(2.5),
        height: RFValue(2.5),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      borderWidth: RFValue(1),
      borderColor: 'white',
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(10),
      color: 'white',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    loginContent: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    input: {
      marginTop: '3%',
      height: RFValue(23),
      width: RFValue(115),
      backgroundColor: Colors.BLACK(0.25),
      color: 'white',
      paddingHorizontal: '5%',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    inputPadding: {
      marginTop: '5%',
    },
    loginButtonView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loginButtonContainer: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      width: RFValue(115),
      height: RFValue(20),
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonContent: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTextView: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(9),
      color: 'white',
    },
    loginTextContainer: {
      flex: 0.4,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    loginText: {
      position: 'absolute',
      bottom: -RFValue(20),
      alignSelf: 'center',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(9),
      color: Colors.ERROR_RED,
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <View style={{
      width: responsiveScreenRepository.newWidth,
      height: responsiveScreenRepository.newHeight,
      backgroundColor: Colors.APP.darkBlue,
    }}
    >
      <View style={styles.contentContainer}>
        <View style={styles.loginContainer}>
          <Image
            source={Icons.UrbanPlayground}
            resizeMode="contain"
            style={styles.icon}
          />

          <View style={styles.loginView}>
            <ExitButton
              onPressed={(): void => navigateToScreen(ScreenTypes.Login)}
            />

            <View style={styles.titleView}>
              <Text style={styles.titleText}>
                Reset Password
              </Text>
            </View>

            <View style={styles.loginContent}>
              <TextInput
                style={styles.input}
                placeholder="Current Password"
                keyboardType="number-pad"
                secureTextEntry
                autoCompleteType="off"
                value={currentPassword}
                onChangeText={onCurrentPasswordChanged}
              />

              <TextInput
                style={[styles.input, styles.inputPadding]}
                placeholder="New Password"
                keyboardType="number-pad"
                secureTextEntry
                autoCompleteType="off"
                value={newPassword}
                onChangeText={onNewPasswordChanged}
              />

              <TextInput
                style={[styles.input, styles.inputPadding]}
                placeholder="Confirm Password"
                keyboardType="number-pad"
                secureTextEntry
                autoCompleteType="off"
                value={confirmPassword}
                onChangeText={onConfirmPasswordChanged}
              />
            </View>

            <View style={styles.loginButtonView}>
              <View style={styles.loginButtonContainer}>
                <TouchableOpacity
                  onPress={onSubmitPressed}
                  style={styles.button}
                  disabled={!valid}
                >
                  <LinearGradient
                    colors={valid ? Colors.LOGIN().active : Colors.LOGIN().inactive}
                    style={styles.buttonContent}
                  >
                    <View style={styles.buttonTextView}>
                      <Text style={styles.buttonText}>
                        Submit
                      </Text>
                    </View>
                  </LinearGradient>
                </TouchableOpacity>
              </View>
            </View>

            <Text style={styles.loginText}>
              {loginMessage}
            </Text>
          </View>
        </View>
      </View>

      {showNotification && (
        <SimpleNotificationModal />
      )}
    </View>
  ));
}
import { BaseSyntheticEvent } from 'react';
import Logger from '../Logger/Logger';

const logger = Logger.Create('Utils');

/**
 * Utility service.
 */
export default class Utils
{
  /**
   * Disables the context menu when the user taps and hold on the screen.
   * @param event The touch event.
   */
  public static disableContextMenu(event: BaseSyntheticEvent<MouseEvent,
    EventTarget & HTMLDivElement, EventTarget>): void
  {
    // Ensures that any touch events does not go past
    // this rendered component.
    event.preventDefault();
  }

  /**
   * Downloads a given file.
   */
  public static downloadFile(filename: string): void
  {
    const url = `${window.location.origin}/Exports/${filename}`;
    logger.info(`Attempting to download the file: ${url}`);

    var tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', url);
    tempLink.click();
  }
}

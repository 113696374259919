/**
 * The Export Status Types.
 */
enum ExportStatusTypes
{
  /**
   * Queued.
   */
  Queued = 0,

  /**
   * InProgress.
   */
  InProgress = 1,

  /**
   * Complete.
   */
  Complete = 2,

  /**
   * Errored.
   */
  Errored = 3,
}

export default ExportStatusTypes;
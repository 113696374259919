import { useObserver } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Image } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { responsiveScreenRepository } from '../../../Domain/Repositories/ResponsiveScreenRepository';
import Fonts from '../../../Domain/Types/Fonts';
import Icons from '../../Assets/Icons';
import { Colors } from '../../../Config/Colors';

interface ButtonWithLabelProps
{
  /**
   * The title.
   */
  title?: string;

  /**
   * The value.
   */
  value?: string;

  /**
   * Callback when the button was pressed.
   */
  onPressed?: () => void;
}

/**
 * Renders the Button With Label.
 */
export default function ButtonWithLabel({
  title = '',
  value = '',
  onPressed,
}: ButtonWithLabelProps): JSX.Element
{
  const styles = useMemo(() => StyleSheet.create({
    dateTimeSelectionContainer: {
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
    dateTimeTitleContainer: {
      flex: 2,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    dateTimeTitleLabel: {
      color: 'white',
      fontSize: RFValue(9),
      letterSpacing: RFValue(0.3),
      fontFamily: Fonts.JudgeMedium,
    },
    dateTimeValueContainer: {
      flex: 4,
      height: '100%',
      backgroundColor: Colors.BLACK(0.25),
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    dateTimeButtonContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    dateTimeValueLabelContainer: {
      flex: 5,
      height: '100%',
      justifyContent: 'center',
    },
    dateTimeValueLabel: {
      color: 'white',
      fontSize: RFValue(8),
      letterSpacing: RFValue(0.3),
      fontFamily: Fonts.JudgeMedium,
      width: '90%',
      marginLeft: '5%',
    },
    dateTimeIconContainer: {
      height: '80%',
      aspectRatio: 1,
      width: undefined,
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dateTimeIcon: {
      width: '40%',
      aspectRatio: 1,
      height: undefined,
    },
    icon: {
      width: '100%',
      height: '100%',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <View style={styles.dateTimeSelectionContainer}>     
      <View style={styles.dateTimeTitleContainer}>
        <Text style={styles.dateTimeTitleLabel}>
          {title}
        </Text>
      </View>

      <View style={styles.dateTimeValueContainer}>
        <TouchableOpacity
          style={styles.dateTimeButtonContainer}
          onPress={onPressed}
        >
          <View style={styles.dateTimeValueLabelContainer}>
            <Text style={styles.dateTimeValueLabel}>
              {value}
            </Text>
          </View>

          <View style={styles.dateTimeIconContainer}>
            <View style={styles.dateTimeIcon}>
              <Image
                source={Icons.Next}
                style={styles.icon}
                resizeMode='contain'
              />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  ));
}
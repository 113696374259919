import { useObserver } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Image } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import Fonts from '../../../Domain/Types/Fonts';
import { responsiveScreenRepository } from '../../../Domain/Repositories/ResponsiveScreenRepository';
import { userRepository } from '../../../Domain/Repositories/UserRepository';
import Icons from '../../Assets/Icons';

interface HeaderProps
{
  /**
   * Callback when the logout was pressed.
   */
  onLogoutPressed?: () => void;
}

/**
 * Renders the Header
 */
export default function Header({
  onLogoutPressed,
}: HeaderProps): JSX.Element
{
  const styles = useMemo(() => StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
    },
    usernameText: {
      fontSize: RFValue(8),
      letterSpacing: RFValue(0.3),
      fontFamily: Fonts.JudgeMedium,
      color: "white",
    },
    logoutButton: {
      height: RFValue(30),
      width: undefined,
      aspectRatio: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoutIcon: {
      width: RFValue(15),
      height: undefined,
      aspectRatio: 1,
    },
    icon: {
      width: '100%',
      height: '100%',
    }
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.logoutButton}
        onPress={onLogoutPressed}
      >
        <View style={styles.logoutIcon}>
          <Image
            source={Icons.LogOut}
            style={styles.icon}
            resizeMode='contain'
          />
        </View>
      </TouchableOpacity>

      <Text style={styles.usernameText}>
        {userRepository.currentUser.userName}
      </Text>
    </View>
  ));
}
/**
 * The available fonts.
 */
enum Fonts
{
  // Judge
  JudgeMedium = 'JudgeMedium',
}

export default Fonts;

import { useObserver } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { responsiveScreenRepository } from '../../../Domain/Repositories/ResponsiveScreenRepository';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';

interface FooterProps
{
  /**
   * Callback when export all was selected.
   */
  onExportAllSelected?: () => void;

  /**
   * Callback when the export file was selected.
   */
  onExportFileSelected?: () => void;

  /**
   * Flag to toggle the export file option.
   */
  disableExportFile?: boolean;
}

/**
 * Renders the Footer components.
 */
export default function Footer({
  disableExportFile = false,
  onExportAllSelected,
  onExportFileSelected,
}: FooterProps): JSX.Element
{
  const styles = useMemo(() => StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    button: {
      width: '30%',
      height: RFValue(25),
      marginHorizontal: '2%',
      paddingHorizontal: '5%',
      backgroundColor: Colors.APP.teal,
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      color: 'white',
      fontSize: RFValue(8),
      letterSpacing: RFValue(0.3),
      fontFamily: Fonts.JudgeMedium,
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.button}
        onPress={onExportAllSelected}
      >
        <Text style={styles.title}>
          View All Exports
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[styles.button, {
          opacity: disableExportFile ? 0.4 : 1,
        }]}
        disabled={disableExportFile}
        onPress={onExportFileSelected}
      >
        <Text style={styles.title}>
          Export File
        </Text>
      </TouchableOpacity>
    </View>
  ));
}
import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { Dropdown } from 'react-native-element-dropdown';
import DropdownModel from '../../../Domain/Models/DropdownModel';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';

const getDefaultDateRange = (): { start: Date, end: Date } => {
  return {
    start: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),
    end: new Date(),
  }
}

/**
 * Minutes.
 */
const minutes = (): DropdownModel[] =>
  {
    const minutes: DropdownModel[] = [];
  
    for (let i = 0; i < 60; i += 1)
    {
      minutes.push({
        label: i.toString().padStart(2, '0'),
        value: i.toString().padStart(2, '0'),
      });
    }
  
    return minutes;
  };
  
  /**
   * Hours.
   */
  const hours = (): DropdownModel[] =>
  {
    const hours: DropdownModel[] = [];
  
    for (let i = 0; i < 24; i += 1)
    {
      hours.push({
        label: i.toString().padStart(2, '0'),
        value: i.toString().padStart(2, '0'),
      });
    }
  
    return hours;
  };

const MAXIMUM_ITEMS_PER_VIEW = 5;
const ITEM_HEIGHT = RFValue(25);
const MAXIMUM_HEIGHT = MAXIMUM_ITEMS_PER_VIEW * ITEM_HEIGHT;

interface TimeDropdownProps
{
  /**
   * The initial time.
   */
  initialTime?: Date;

  /**
   * Callback when the time changed.
   * @param date The date.
   */
  onTimeChanged?: (date: Date) => void;

  /**
   * Use local time instead of UTC.
   */
  useLocalTime?: boolean;
}

/**
 * Time Dropdown.
 * @returns JSX.Element.
 */
export default function TimeDropdown({
  initialTime = getDefaultDateRange().start,
  onTimeChanged,
}: TimeDropdownProps): JSX.Element
{
  const [initialHour, setInitialHour] = useState<DropdownModel | null>(null);
  const [initialMinutes, setInitialMinutes] = useState<DropdownModel | null>(null);

  useEffect(() =>
  {
    setHours();
    setMinutes();
  }, []);

  const setHours = (): void =>
  {
    const hour = hours().find((
      x: DropdownModel,
    ) => x.value === initialTime.getHours().toString().padStart(2, '0'));

    setInitialHour(hour ?? hours()[23]);
  };

  const setMinutes = (): void =>
  {
    const minute = minutes().find((
      x: DropdownModel,
    ) => x.value === initialTime.getMinutes().toString().padStart(2, '0'));

    setInitialMinutes(minute ?? minutes()[59]);
  };

  const onHoursChanged = (item: DropdownModel): void =>
  {
    setInitialHour(item);
    onTimeValueChanged(item);
  };

  const onMinutesChanged = (item: DropdownModel): void =>
  {
    setInitialMinutes(item);
    onTimeValueChanged(item, false);
  };

  const onTimeValueChanged = (item: DropdownModel, isHour = true): void =>
  {
    if (isHour)
    {
      const hour = parseInt(item.value, 10);
      const minutes = initialMinutes === null ? 59 : parseInt(initialMinutes.value, 10);
      const time = new Date(2023, 8, 11, hour, minutes);
      updateCallback(time);
      return;
    }

    const hour = initialHour === null ? 23 : parseInt(initialHour.value, 10);
    const minutes = parseInt(item.value, 10);
    const time = new Date(2023, 8, 11, hour, minutes);
    updateCallback(time);
  };

  const updateCallback = (time: Date): void =>
  {
    if (onTimeChanged !== undefined)
    {
      onTimeChanged(time);
    }
  };

  return (
    <View style={styles.container}>
      <Dropdown
        style={styles.dropdown}
        placeholderStyle={styles.dropdownPlaceholderText}
        selectedTextStyle={styles.dropdownSelectedText}
        containerStyle={styles.dropdownListContainer}
        itemContainerStyle={styles.dropdownListItemContainer}
        itemTextStyle={styles.dropdownListItemText}
        data={hours()}
        maxHeight={MAXIMUM_HEIGHT}
        labelField="label"
        valueField="value"
        value={initialHour}
        activeColor={Colors.DROPDOWN.selected}
        iconStyle={styles.dropdownArrowIcon}
        iconColor={Colors.DROPDOWN.icon}
        onChange={onHoursChanged}
      />

      <View style={styles.spacing}/>

      <Dropdown
        style={styles.dropdown}
        placeholderStyle={styles.dropdownPlaceholderText}
        selectedTextStyle={styles.dropdownSelectedText}
        containerStyle={styles.dropdownListContainer}
        itemContainerStyle={styles.dropdownListItemContainer}
        itemTextStyle={styles.dropdownListItemText}
        data={minutes()}
        maxHeight={MAXIMUM_HEIGHT}
        labelField="label"
        valueField="value"
        value={initialMinutes}
        activeColor={Colors.DROPDOWN.selected}
        iconStyle={styles.dropdownArrowIcon}
        iconColor={Colors.DROPDOWN.icon}
        onChange={onMinutesChanged}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacing: {
    flex: 0.05,
  },
  dropdown: {
    backgroundColor: Colors.DROPDOWN.unselected,
    height: '50%',
    flex: 1,
    padding: '5%',
    borderColor: Colors.DROPDOWN.border,
    borderWidth: RFValue(1),
  },
  dropdownPlaceholderText: {
    textAlign: 'center',
    fontSize: RFValue(9),
    width: '70%',
    color: 'white',
    fontFamily: Fonts.JudgeMedium,
  },
  dropdownSelectedText: {
    textAlign: 'left',
    fontSize: RFValue(9),
    color: 'white',
    marginLeft: '5%',
    fontFamily: Fonts.JudgeMedium,
  },
  dropdownListContainer: {
    backgroundColor: Colors.DROPDOWN.unselected,
    overflow: 'hidden',
    borderColor: Colors.DROPDOWN.border,
    borderWidth: RFValue(1),
  },
  dropdownListItemContainer: {
    height: ITEM_HEIGHT,
    justifyContent: 'center',
  },
  dropdownListItemText: {
    fontSize: RFValue(9),
    textAlign: 'center',
    justifyContent: 'center',
    color: 'white',
    fontFamily: Fonts.JudgeMedium,
  },
  dropdownArrowIcon: {
    width: RFValue(10),
    height: undefined,
    aspectRatio: 1,
    position: 'absolute',
    right: 0,
  },
  highlight: {
    borderColor: Colors.ERROR_RED,
    borderWidth: RFValue(1),
  },
});

/**
 * Filter types.
 */
enum FilterTypes
{
  /**
   * From.
   */
  From = 'From',
  
  /**
   * To.
   */
  To = 'To',
}

export default FilterTypes;

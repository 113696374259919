/* eslint-disable no-new */
import * as Font from 'expo-font';
import Logger from '../Logger/Logger';

const logger = Logger.Create('FontHandler');

/**
 * Font Handler
 */
export default class FontHandler
{
  /**
   * Loads all the required fonts. This should be called at highest component
   * during the initialisation phase. In this case, App.tsx.
   */
  public static async initialise(): Promise<boolean>
  {
    await Font.loadAsync({
      JudgeMedium: {
        uri: require('../../Fonts/Judge/JudgeMedium.otf'),
      },
    })
      .then(() =>
      {
        logger.info('Successfully loaded all the fonts required!');
      })
      .catch((error) =>
      {
        logger.error(`Failed to load the requested font because of the error ${error}. The application may not display the content correctly.`);
      });

    return true;
  }
}

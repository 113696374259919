import Logger from '../Logger/Logger';
import HttpService from './HttpService';
import { ErrorCode, ThirtyOneError } from '../Error/ThirtyOneError';
import SsoService from './SsoService';
import { appRepository } from '../Repositories/AppRepository';
import { sessionTokenRepository } from '../Repositories/SessionTokenRepository';

const MissingEnvMessage = 'No environment was set!';
const logger = Logger.Create('ApiService');

/**
 * Handles the Api operations.
 */
export default class ApiService
{
  /**
   * Make a POST request to the server
   * @param url URL to send the request to.
   * @param data The data.
   */
  public static async post<T = object>(url: string, data?: object): Promise<T>
  {
    if (appRepository.environment === undefined)
    {
      logger.error(MissingEnvMessage);
      return Promise.reject(MissingEnvMessage);
    }

    const fullUrl = appRepository.getServerUrl() + url;

    let jsonData: string | undefined;

    if (data !== undefined)
    {
      jsonData = JSON.stringify(data);
    }

    return ApiService.makeRequestWithRefresh<T>(async () =>
    {
      const token = await sessionTokenRepository.getJwtToken();
      return HttpService.post<T>(fullUrl, jsonData, token);
    });
  }

  private static async makeRequestWithRefresh<T>(request: () => Promise<T>): Promise<T>
  {
    try
    {
      // Make the first request.
      return await request();
    }
    catch (error)
    {
      const thirty1Error = error as ThirtyOneError;

      // If the error is not unauthorised, continue as normal.
      if (thirty1Error.errorCode !== ErrorCode.Unauthorised)
      {
        return Promise.reject(error);
      }
    }

    // Refresh the token.
    logger.info('Refreshing token...');
    await SsoService.refreshToken();

    try
    {
      // Make the second request attempt.
      return await request();
    }
    catch (error)
    {
      const thirty1Error = error as ThirtyOneError;

      // If the error is not unauthorised, continue as normal.
      if (thirty1Error.errorCode !== ErrorCode.Unauthorised)
      {
        return Promise.reject(error);
      }

      logger.info('Clearing token repository...');
      sessionTokenRepository.clear();
      return Promise.reject(error);
    }
  }
}

/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */

import { Platform } from 'react-native';

enum LogLevel
{
  Trace = 'Trace',
  Info = 'Info',
  Debug = 'Debug',
  Warn = 'Warn',
  Error = 'Error',
  Fatal = 'Fatal',
}

/**
 * The colors of the text according to the log levels.
 */
const LevelColors = [
  {
    LogLevel: LogLevel.Trace,
    TextColor: 'grey',
  },
  {
    LogLevel: LogLevel.Info,
    TextColor: 'green',
  },
  {
    LogLevel: LogLevel.Debug,
    TextColor: 'blue',
  },
  {
    LogLevel: LogLevel.Warn,
    TextColor: 'white',
    BackgroundColor: 'rgb(236, 180, 67)',
  },
  {
    LogLevel: LogLevel.Error,
    TextColor: 'red',
  },
  {
    LogLevel: LogLevel.Fatal,
    TextColor: 'white',
    BackgroundColor: 'red',
  },
];

/**
 * Logger.
 */
export default class Logger
{
  protected name: string;

  /**
   * Instantiates the Logger class.
   * @param name The logger name.
   */
  constructor(name: string)
  {
    this.name = Logger.padOrTrim(name, 15);
  }

  /**
   * Create a new logger instance.
   * @param name Class name
   * @returns New logger instance.
   */
  public static Create(name: string): Logger
  {
    return new Logger(name);
  }

  /**
   * Trace log.
   * @param message The message.
   */
  public trace(message: string): void
  {
    this.displayMessage(message, LogLevel.Trace);
  }

  /**
   * Info log.
   * @param message The message.
   */
  public info(message: string): void
  {
    this.displayMessage(message, LogLevel.Info);
  }

  /**
   * Debug log.
   * @param message The message.
   */
  public debug(message: string): void
  {
    this.displayMessage(message, LogLevel.Debug);
  }

  /**
   * Warn log.
   * @param message The message.
   */
  public warn(message: string): void
  {
    this.displayMessage(message, LogLevel.Warn);
  }

  /**
   * Error log.
   * @param message The message.
   */
  public error(message: string): void
  {
    this.displayMessage(message, LogLevel.Error);
  }

  /**
   * Error log.
   * @param message The message.
   */
  public fatal(message: string): void
  {
    this.displayMessage(message, LogLevel.Fatal);
  }

  private displayMessage(message: string, level: LogLevel): void
  {
    const formattedMessage = `${new Date().toISOString()} | ${Logger.padOrTrim(level, 5)} | ${Platform.OS.toLocaleUpperCase()} | ${this.name} | ${message}`;

    console.log(`%c ${formattedMessage}`, this.getLogLevelCss(level));
  }

  private getLogLevelCss(level: LogLevel): string
  {
    const matchingLevelItem = LevelColors.find((obj) => obj.LogLevel === level);

    return `color: ${matchingLevelItem?.TextColor}; background: ${matchingLevelItem?.BackgroundColor}`;
  }

  private static padOrTrim(value: string, length: number): string
  {
    let message: string = value;

    if (message.length > length)
    {
      message = message.substr(message.length - length, length);
    }
    else if (message.length < length)
    {
      const pad = Array(length).join(' ');
      message = `${pad}${message}`;
      message = message.substr(message.length - length, length);
    }

    return message;
  }
}
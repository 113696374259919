import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { DISABLE_SIGNALR_CONNECTION, SIGNALR_LOGLEVEL } from '../../Config/Parameters';
import Logger from '../Logger/Logger';
import SignalREvent from '../Models/SignalREvent';
import SignalREventTypes from '../Types/SignalREventTypes';
import { ErrorCode, ThirtyOneError } from '../Error/ThirtyOneError';
import { getEnvironment } from '../../Config/Environment';

const logger = Logger.Create('SignalRService');

/**
 * Service to handle signalR events.
*/
export default class SignalRService
{
  /**
   * The callback that gets fired when the connection was lost and is reconnecting.
   */
  public static OnSignalRReconnectingCallback?: () => void;

  /**
   * The callback that gets fired when the connection was lost and is now connected.
   */
  public static OnSignalRReconnectedCallback?: () => void;

  /**
   * The callback that gets fired when the connection is lost.
   */
  public static OnSignalRDisconnectedCallback?: () => void;

  /**
   * The callback that gets fired when the connection has been established.
   */
  public static OnSignalRConnectedCallback?: () => void;

  public static OnSignalRConnectionFailedCallback?: () => void;

  private static connection: HubConnection;

  private static onMessageReceivedCallback: ((signalREvent: SignalREvent) => void)[] = []

  private static onSignalRDisconnectedCallbacks: (() => void)[] = []

  private static isConnecting = false;

  private static JoinGroupMethodName = "JoinGroup";

  /**
   * Connect to the server.
   */
  public static async Connect(): Promise<void>
  {
    const environment = getEnvironment();

    if (environment === undefined || environment.apiBase === undefined)
    {
      logger.error('Failed to connect to SignalR hub because no environment was set!');
      return;
    }

    if (DISABLE_SIGNALR_CONNECTION)
    {
      return;
    }

    // Build the connection
    this.connection = new HubConnectionBuilder()
      .withUrl(`${environment.apiBase}/api/v1/statusHub`)
      .configureLogging(SIGNALR_LOGLEVEL)
      .build();

    this.SubscribeToSignalREventMethods();

    logger.info(`Attempting to connect to the SignalR Server...`);

    // If the signalR is already trying to connect,
    // then wait for that to finish first before we try it again.
    if (this.isConnecting)
    {
      return;
    }

    this.isConnecting = true;

    // Start the connection
    await this.connection.start()
      .then((): void =>
      {
        this.OnConnectionSuccessful();
        this.isConnecting = false;
      })
      .catch((errorMessage: string): Promise<void> =>
      {
        if (this.OnSignalRConnectionFailedCallback)
        {
          this.OnSignalRConnectionFailedCallback();
        }

        this.isConnecting = false;

        return Promise.reject(
          new ThirtyOneError(ErrorCode.SignalRConnectionFailure,
            `Failed to connect to the SignalR server: ${errorMessage}`),
        );
      });
  }

  /**
   * Subscribe to the message received callback.
   * @param callback The callback method to subscribe to.
   */
  public static RegisterMessageReceivedCallback(callback:
    (signalREvent: SignalREvent) => void): void
  {
    this.onMessageReceivedCallback.push(callback);
    logger.trace(`Registering message received callback. There is currently ${this.onMessageReceivedCallback.length} listeners!`);
  }

  /**
   * Subscribe to the disconnected callback.
   * @param callback The callback method to subscribe to.
   */
  public static RegisterOnDisconnectedCallback(callback:
    () => void): void
  {
    this.onSignalRDisconnectedCallbacks.push(callback);
    logger.trace(`Registering onDisconnected callback. There is currently ${this.onSignalRDisconnectedCallbacks.length} listeners!`);
  }

  /**
   * Unregister all disconnected callbacks.
   */
  public static UnregisterAllDisconnectedCallbacks(): void
  {
    this.onSignalRDisconnectedCallbacks.length = 0;
  }

  /**
   * Unregister all message received callbacks.
   */
  public static UnregisterAllMessageReceivedCallbacks(): void
  {
    this.onMessageReceivedCallback.length = 0;
  }

  /**
   * Unsubscribe from the message receive callback.
   * @param callback The callback method to unsubscribe.
   */
  public static UnregisterMessageReceivedCallback(callback:
    (ignalREvent: SignalREvent) => void): void
  {
    const index = this.onMessageReceivedCallback.indexOf(callback, 0);

    if (index > -1)
    {
      this.onMessageReceivedCallback.splice(index, 1);
    }

    logger.trace(`Unregistering message received callback. There is currently ${this.onMessageReceivedCallback.length} listeners!`);
  }

  /**
   * Disconnect from the server.
   */
  public static Disconnect(): void
  {
    if (this.connection === undefined)
    {
      return;
    }

    this.connection.stop();

    logger.warn('The application is intentionally severing the SignalR connection.');

    // Remove all subscribers.
    this.RemoveAllSubscribers();
  }

  /**
   * Returns the SignalR connection state.
   * @returns SignalR connection state.
   */
  public static GetConnectionState(): boolean
  {
    if (this.connection === undefined)
    {
      logger.error('Unable to return the SignalR connection state '
      + 'because the service has not been initialised.');
      return false;
    }

    return this.connection.state === HubConnectionState.Connected;
  }

  /**
   * Connect to a group.
   * @param groupId The group id.
   */
  public static ConnectToGroup(groupId: string): void
  {
    this.connection.invoke(this.JoinGroupMethodName, groupId);
  }

  /**
   * Subscribe to all signalR event method callbacks.
   */
  private static SubscribeToSignalREventMethods(): void
  {
    // Get all the available event types as a list.
    const keys = Object.keys(SignalREventTypes);

    // Loop through the available event types and send the
    // messages received to the callback.
    for (let i = 0; i < keys.length; i += 1)
    {
      this.connection.on(keys[i], (receivedMessage) =>
      {
        this.HandleReceivedMessages({
          eventType: keys[i] as SignalREventTypes,
          message: receivedMessage,
        });
      });
    }

    this.connection.onreconnecting((
      error: Error | undefined,
    ): void => this.OnReconnecting(error));

    this.connection.onreconnected(
      (connectionId?: string | undefined): void => this.OnConnectionResumed(connectionId),
    );

    this.connection.onclose((error: Error | undefined): void => this.OnConnectionClosed(error));
  }

  private static RemoveAllSubscribers(): void
  {
    this.onMessageReceivedCallback = [];
  }

  private static HandleReceivedMessages(signalREvent: SignalREvent): void
  {
    // Parse and cast the received message as a JSON string
    const jsonString = JSON.stringify(signalREvent.message);

    // Create a new event containing the JSON string.
    const event: SignalREvent = {
      eventType: signalREvent.eventType,
      message: jsonString,
    };

    for (let i = 0; i < this.onMessageReceivedCallback.length; i += 1)
    {
      this.onMessageReceivedCallback[i](event);
    }
  }

  private static OnConnectionSuccessful(): void
  {
    if (this.OnSignalRConnectedCallback !== undefined)
    {
      this.OnSignalRConnectedCallback();
    }
  }

  private static OnReconnecting(error?: Error | undefined): void
  {
    logger.fatal(`Lost SignalR connection. Attempting to reconnect to the signalR server again. Error: ${error?.message}`);

    if (this.OnSignalRReconnectingCallback)
    {
      this.OnSignalRReconnectingCallback();
    }
  }

  private static OnConnectionResumed(connectionId: string | undefined): void
  {
    logger.info(`An active SignalR connection with id: ${connectionId} has resumed. Phew!`);

    if (this.OnSignalRReconnectedCallback !== undefined)
    {
      this.OnSignalRReconnectedCallback();
    }
  }

  private static OnConnectionClosed(error?: Error | undefined): void
  {
    logger.fatal(`Oh no! An active SignalR connection was disconnected! Error: ${JSON.stringify(error?.message)}`);

    if (this.OnSignalRDisconnectedCallback)
    {
      this.OnSignalRDisconnectedCallback();
    }
  }
}

import Logger from "../Logger/Logger";
import SignalRService from "./SignalRService";
import ExportResponse from "../Models/ExportResponse";
import { PromiseFail, PromiseResponse, PromiseSuccess } from '../Models/PromiseResponse';
import ApiService from './ApiService';
import { ThirtyOneError } from '../Error/ThirtyOneError';

const logger = Logger.Create('AuditDataService');

/**
 * The Audit Data Service
 */
export default class AuditDataService
{
  /**
   * Export.
   * @param venueName The venue name.
   * @param application The application name.
   * @param start The start date.
   * @param end The end date.
   * @returns 
   */
  public static async export(venueName: string, application: string, start: string, end: string): Promise<PromiseResponse>
  {
    const url = `/api/Audit/Export`;

    const body = {
      venueName,
      application,
      start,
      end,
    };

    return ApiService.post<ExportResponse>(url, body)
      .then((response: ExportResponse) =>
      {
        SignalRService.ConnectToGroup(response.id);
        return PromiseSuccess();
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Request failed: ${error.errorCode}`;
        logger.error(message);
        return PromiseFail(message);
      });
  }
}
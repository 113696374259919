import Users from '../Domain/Models/Users';

const DefaultUser: Users = {
  id: '',
  roles: [],
  userName: '',
};

export const Constants = {
  DefaultUser,
}
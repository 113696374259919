/**
 * The available screen types.
 */
enum ScreenTypes
{
  Login = '',
  Main = 'Main',
  ResetPassword = 'ResetPassword',
}

export default ScreenTypes;

import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import TimeDropdown from './TimeDropdown';
import FilterTypes from '../../../Domain/Types/FilterTypes';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';

const getDefaultDateRange = (): { start: Date, end: Date } => {
  return {
    start: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),
    end: new Date(),
  }
}

interface DateTimeSelectionProps
{
  /**
   * Title.
   */
  title?: string;

  /**
   * The date.
   */
  date?: Date;

  /**
   * Initial time.
   */
  initialTime?: Date;

  /**
   * Callback when the date button was selected.
   */
  onDateButtonSelected?: (filterType: FilterTypes) => void;

  /**
   * Highlight the date option.
   */
  highlight?: boolean;

  /**
   * The current filter type.
   */
  filterType?: FilterTypes;

  /**
   * Callback when the time was selected.
   * @param time The time.
   */
  onTimeSelected?: (time: Date) => void;

  /**
   * Show time selection only.
   */
  showDate?: boolean;

  /**
   * Use local time instead of UTC time.
   */
  useLocalTime?: boolean;
}

/**
 * Date Time Selection
 * @returns JSX.Element.
 */
export default function DateTimeSelection({
  title = 'From',
  date = getDefaultDateRange().start,
  initialTime = getDefaultDateRange().start,
  highlight = false,
  filterType = FilterTypes.From,
  onDateButtonSelected,
  onTimeSelected,
  showDate = true,
  useLocalTime = false,
}: DateTimeSelectionProps): JSX.Element
{
  const onDateButtonWasPressed = (): void =>
  {
    if (onDateButtonSelected !== undefined)
    {
      onDateButtonSelected(filterType);
    }
  };

  const onTimeChanged = (time: Date): void =>
  {
    if (onTimeSelected !== undefined)
    {
      onTimeSelected(time);
    }
  };

  return (
    <View style={styles.content}>
      <View style={[styles.titleView, {
        flex: showDate ? 0.1 : 0.3,
      }]}
      >
        <Text style={styles.titleText}>
          {title}
        </Text>
      </View>

      {showDate && (
        <View style={styles.dateView}>
          <TouchableOpacity
            onPress={onDateButtonWasPressed}
            style={[styles.dateButton, highlight ? styles.highlight : undefined]}
          >
            <Text style={styles.dateText}>
              {date.toLocaleDateString()}
            </Text>
          </TouchableOpacity>
        </View>
      )}

      <View style={[styles.timeDropdownView, {
        flex: showDate ? 0.3 : 0.7,
      }]}
      >
        <TimeDropdown
          onTimeChanged={onTimeChanged}
          initialTime={initialTime}
          useLocalTime={useLocalTime}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    width: '100%',
    height: '30%',
    flexDirection: 'row',
  },
  titleView: {
    flex: 0.1,
    height: '100%',
    justifyContent: 'center',
  },
  titleText: {
    textAlign: 'left',
    fontSize: RFValue(9),
    color: 'white',
    fontFamily: Fonts.JudgeMedium,
  },
  dateView: {
    flex: 0.6,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateButton: {
    width: '98%',
    height: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.DROPDOWN.unselected,
    borderColor: Colors.DROPDOWN.border,
    borderWidth: RFValue(1),
  },
  dateText: {
    width: '95%',
    textAlign: 'center',
    fontSize: RFValue(9),
    color: 'white',
    fontFamily: Fonts.JudgeMedium,
  },
  timeDropdownView: {
    flex: 0.3,
    height: '100%',
  },
  highlight: {
    borderColor: Colors.APP.teal,
    borderWidth: RFValue(1),
  },
});

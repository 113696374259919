import React, { useLayoutEffect } from 'react';
import {
  Dimensions,
  ScrollView,
  StyleSheet,
} from 'react-native';
import * as RouterWeb from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import ScreenTypes from './Domain/Types/ScreenTypes';
import Main from './Components/MainScreen/Main';
import LoginScreen from './Components/LoginScreen/LoginScreen';
import { getEnvironment, getOidConfig } from './Config/Environment';
import { responsiveScreenRepository } from './Domain/Repositories/ResponsiveScreenRepository';
import { appRepository } from './Domain/Repositories/AppRepository';
import { roleRepository } from './Domain/Repositories/RoleRepository';
import { userRepository } from './Domain/Repositories/UserRepository';
import Logger from './Domain/Logger/Logger';
import ResetPasswordScreen from './Components/ResetPasswordScreen/ResetPasswordScreen';

const logger = Logger.Create('App');
const WindowDimensions = Dimensions.get('window');

/**
 * Platform specific imports.
 */
const Router = RouterWeb.HashRouter;
const Route = RouterWeb.Route;
const Switch = RouterWeb.Switch;


/**
 * Top level implementation for handling the application navigation.
 * @returns JSX Element.
 */
export default function Root(): JSX.Element
{
  /**
   * Returns the navigation path which is platform dependant.
   * @param target Target screen to navigate to.
   * @returns Platform dependant absolute path
   */
  const getNavigationPath = (target: string): string => `/${target}`;

  useLayoutEffect(() =>
  {
    (async (): Promise<void> =>
    {
      responsiveScreenRepository.loaded = false;
      logger.info('Initialising the application...');

      // Set the runtime parameters.
      appRepository.setRuntimeParameters(getEnvironment(), getOidConfig());
      responsiveScreenRepository.subscribe();
      
      /**
       * Add any application startup requirements here...
       */
      await userRepository.retrieveFromStorage();
      await roleRepository.retrieveRoleFromStorage();

      responsiveScreenRepository.loaded = true;
    })();
  }, []);


  return useObserver(() => (
    <ScrollView
      style={[styles.container, {
        width: responsiveScreenRepository.newWidth,
        height: responsiveScreenRepository.newHeight,
      }]}
      contentContainerStyle={styles.scrollViewContainer}
    >
      <Router>
        <Switch>
          <Route
            exact
            path={getNavigationPath(ScreenTypes.Login)}
            component={LoginScreen}
          />
          <Route
            exact
            path={getNavigationPath(ScreenTypes.Main)}
            component={Main}
          />
          <Route
            exact
            path={getNavigationPath(ScreenTypes.ResetPassword)}
            component={ResetPasswordScreen}
          />
        </Switch>
      </Router>
    </ScrollView>
  ));
}

const styles = StyleSheet.create({
  container: {
    width: WindowDimensions.width,
    height: WindowDimensions.height,
  },
  scrollViewContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

import React from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import Icons from '../../Assets/Icons';

interface ExitButtonProps
{
  /**
   * Callback when the exit button was pressed.
   */
  onPressed?: () => void;

  /**
   * The icon.
   */
  icon?: ImageSourcePropType;
}

/**
 * Exit Button
 * @returns JSX.Element.
 */
export default function ExitButton({
  onPressed,
  icon = Icons.Exit,
}: ExitButtonProps): JSX.Element
{
  const onExitButtonPressed = (): void =>
  {
    if (onPressed !== undefined)
    {
      onPressed();
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.icon}
        onPress={onExitButtonPressed}
      >
        <Image
          source={icon}
          style={styles.icon}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: RFValue(16),
    width: undefined,
    aspectRatio: 1,
    position: 'absolute',
    top: RFValue(8),
    right: RFValue(8),
    zIndex: 10,
  },
  icon: {
    height: '100%',
    width: undefined,
    aspectRatio: 1,
    overflow: 'hidden',
  },
});

import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import Logger from '../Logger/Logger';
import { ErrorCode, ThirtyOneError } from '../Error/ThirtyOneError';

const logger = Logger.Create('HttpService');
const requestTimeout = 20000;

/**
 * Handles the HTTP operations.
 */
export default class HttpService
{
  /**
   * Makes a HEAD request.
   * @param url The url.
   * @returns Promise.
   */
  public static async head(url: string): Promise<boolean>
  {
    const response = await axios.head(url)
      .catch((error: AxiosError) =>
      {
        if (error.response === undefined)
        {
          logger.fatal(`Http request error: ${JSON.stringify(error)}`);
          return Promise.reject(new ThirtyOneError(ErrorCode.RequestError, error.message));
        }

        return error.response;
      });

    return response.status >= 200 && response.status < 300;
  }

  /**
   * Make a GET request to the server
   * @param url URL to send the request to.
   * @param token The JWT token.
   */
  public static async get<T = object>(url: string, token?: string): Promise<T>
  {
    const config: AxiosRequestConfig = {
      timeout: requestTimeout,
      headers: this.createHeader(token),
    };

    logger.info(`Sending request: ${url}`);
    const response = await axios.get(url, config)
      .catch((error: AxiosError) =>
      {
        if (error.response === undefined)
        {
          logger.fatal(`Http request error: ${JSON.stringify(error)}`);
          return Promise.reject(new ThirtyOneError(ErrorCode.RequestError, error.message));
        }

        return error.response;
      });

    return this.handleResponse(response);
  }

  /**
   * Make a POST request to the server
   * @param url URL to send the request to.
   * @param data The data.
   * @param token The JWT token.
   */
  public static async post<T = object>(url: string, data?: string, token?: string): Promise<T>
  {
    const config: AxiosRequestConfig = {
      timeout: requestTimeout,
      headers: this.createHeader(token),
    };

    logger.info(`Sending request: ${url}`);
    const response = await axios.post(url, data, config)
      .catch((error: AxiosError) =>
      {
        if (error.response === undefined)
        {
          logger.fatal(`Http request error: ${JSON.stringify(error)}`);
          return Promise.reject(new ThirtyOneError(ErrorCode.RequestError, error.message));
        }

        return error.response;
      });

    return this.handleResponse(response);
  }

  /**
   * Make a POST request to the server as a form.
   * @param url URL to send the request to.
   * @param queryString The query string.
   * @param token The JWT token.
   */
  public static async postForm<T = object>(
    url: string,
    queryString: string,
    token?: string,
  ): Promise<T>
  {
    const config: AxiosRequestConfig = {
      timeout: requestTimeout,
      headers: this.createFormHeader(token),
    };

    logger.info(`Sending request: ${url}`);
    const response = await axios.post(url, queryString, config)
      .catch((error: AxiosError) =>
      {
        if (error.response === undefined)
        {
          logger.fatal(`Http request error: ${JSON.stringify(error)}`);
          return Promise.reject(new ThirtyOneError(ErrorCode.RequestError, error.message));
        }

        return error.response;
      });

    return this.handleResponse(response);
  }

  /**
   * Make a PUT request to the server
   * @param url URL to send the request to.
   * @param data The data.
   * @param token The JWT token.
   */
  public static async put<T = object>(url: string, data: string, token?: string): Promise<T>
  {
    const config: AxiosRequestConfig = {
      timeout: requestTimeout,
      headers: this.createHeader(token),
    };

    logger.info(`Sending request: ${url}`);
    const response = await axios.put(url, data, config)
      .catch((error: AxiosError) =>
      {
        if (error.response === undefined)
        {
          logger.fatal(`Http request error: ${JSON.stringify(error)}`);
          return Promise.reject(new ThirtyOneError(ErrorCode.RequestError, error.message));
        }

        return error.response;
      });

    return this.handleResponse(response);
  }

  /**
   * Make a DELETE request to the server
   * @param url URL to send the request to.
   * @param token The JWT token.
   */
  public static async delete<T = object>(url: string, token?: string): Promise<T>
  {
    const config: AxiosRequestConfig = {
      timeout: requestTimeout,
      headers: this.createHeader(token),
    };

    logger.info(`Sending request: ${url}`);
    const response = await axios.delete(url, config)
      .catch((error: AxiosError) =>
      {
        if (error.response === undefined)
        {
          logger.fatal(`Http request error: ${JSON.stringify(error)}`);
          return Promise.reject(new ThirtyOneError(ErrorCode.RequestError, error.message));
        }

        return error.response;
      });

    return this.handleResponse(response);
  }

  private static async handleResponse<T>(response: AxiosResponse): Promise<T>
  {
    if (response.status === 400)
    {
      return Promise.reject(new ThirtyOneError(
        ErrorCode.BadRequest, JSON.stringify(response.data),
      ));
    }

    if (response.status === 401)
    {
      return Promise.reject(new ThirtyOneError(
        ErrorCode.Unauthorised, JSON.stringify(response.data),
      ));
    }

    if (response.status === 404)
    {
      return Promise.reject(new ThirtyOneError(
        ErrorCode.NotFound, response.data,
      ));
    }

    // Failed dependency: Used when the user cannot be found.
    if (response.status === 424)
    {
      return Promise.reject(new ThirtyOneError(
        ErrorCode.UnknownUser, response.data,
      ));
    }

    if (response.status === 500)
    {
      return Promise.reject(new ThirtyOneError(
        ErrorCode.ServerError, JSON.stringify(response.data),
      ));
    }

    if (response.status !== 200)
    {
      return Promise.reject(new ThirtyOneError(
        ErrorCode.RequestError, JSON.stringify(response.data),
      ));
    }

    return response.data;
  }

  /**
   * Creates and returns a header using user token.
   */
  private static createHeader(accessToken?: string): Record<string, string>
  {
    if (accessToken)
    {
      return {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
    }

    return {
      'Content-Type': 'application/json',
    };
  }

  /**
   * Creates and returns a header using user token.
   */
  private static createFormHeader(accessToken?: string): Record<string, string>
  {
    if (accessToken)
    {
      return {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    }

    return {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  }
}

import React, { useMemo, useState } from 'react';
import {
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import DatePicker from 'react-native-modern-datepicker';
import { Colors } from '../../../Config/Colors';
import DateTimeSelection from './DateTimeSelection';
import ExitButton from './ExitButton';
import FilterTypes from '../../../Domain/Types/FilterTypes';
import Fonts from '../../../Domain/Types/Fonts';
import { responsiveScreenRepository } from '../../../Domain/Repositories/ResponsiveScreenRepository';

const ERROR_MESSAGE = '\'To\' date cannot be lower than \'From\' date';

interface DateTimeFilterModalProps
{
  /**
   * Show.
   */
  show?: boolean;

  /**
   * Callback when the date time was selected.
   * @param from The from date.
   * @param to The to date.
   */
  onDateTimeSelected?: (from: Date, to: Date) => void;

  /**
   * Callback when the exit button was pressed.
   */
  onExitPressed?: () => void;
}

/**
 * Date Time Filter Modal
 * @returns JSX.Element.
 */
export default function DateTimeFilterModal({
  show,
  onDateTimeSelected,
  onExitPressed,
}: DateTimeFilterModalProps): JSX.Element
{
  const getDefaultDateRange = (): { start: Date, end: Date } => {
    return {
      start: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)),
      end: new Date(),
    }
  }

  const [calendarVisible, setCalendarVisible] = useState(false);
  const [currentDateType, setCurrentDateType] = useState<FilterTypes | null>(null);
  const [fromDate, setFromDate] = useState(getDefaultDateRange().start);
  const [toDate, setToDate] = useState(getDefaultDateRange().end);
  const [fromTime, setFromTime] = useState(getDefaultDateRange().start);
  const [toTime, setToTime] = useState(getDefaultDateRange().end);

  const hideCalendar = (): void =>
  {
    setCurrentDateType(null);
    setCalendarVisible(false);
  };

  const showCalendar = (filterType: FilterTypes): void =>
  {
    setCurrentDateType(filterType);
    setCalendarVisible(true);
  };

  const isValid = (): boolean => toDate >= fromDate;

  const onDateSelected = (selectedDate: string): void =>
  {
    const date = new Date(selectedDate);

    if (currentDateType === FilterTypes.From)
    {
      setFromDate(date);
    }
    else
    {
      setToDate(date);
    }

    hideCalendar();
  };

  const onApplySelected = (): void =>
  {
    const from = constructDateAndTime(fromDate, fromTime);
    const to = constructDateAndTime(toDate, toTime);

    if (onDateTimeSelected !== undefined)
    {
      onDateTimeSelected(from, to);
    }

    onModalClosed();
  };

  const onModalClosed = (): void =>
  {
    hideCalendar();

    if (onExitPressed !== undefined)
    {
      onExitPressed();
    }
  };

  const formatDateToModalString = (date: Date): string =>
  {
    const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return dateString;
  }

  const constructDateAndTime = (date: Date, time: Date): Date =>
  {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
    );
  }

  const styles = useMemo(() => StyleSheet.create({
    container: {
      backgroundColor: Colors.APP_OPACITY(0.8).darkerBlue,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      height: RFPercentage(25),
      width: undefined,
      aspectRatio: 1.5,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
      justifyContent: 'center',
      flexDirection: 'row',
    },
    contentView: {
      flex: 0.55,
      width: '100%',
      alignItems: 'center',
    },
    titleView: {
      flex: 0.15,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    titleText: {
      textAlign: 'center',
      fontSize: RFValue(12),
      color: 'white',
      fontFamily: Fonts.JudgeMedium,
    },
    dateSelectionView: {
      flex: 0.7,
      width: '70%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    applyButtonView: {
      flex: 0.15,
      width: '100%',
      alignItems: 'center',
    },
    button: {
      width: '30%',
      height: '80%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonContent: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTextView: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      width: '95%',
      textAlign: 'center',
      fontSize: RFValue(9),
      color: 'white',
      fontFamily: Fonts.JudgeMedium,
    },
    errorText: {
      position: 'absolute',
      bottom: -RFValue(18),
      width: '95%',
      textAlign: 'center',
      fontSize: RFValue(9),
      color: Colors.APP.red,
      textTransform: 'capitalize',
      fontFamily: Fonts.JudgeMedium,
    },
    calendarView: {
      flex: 0.4,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <Modal
      visible={show}
      transparent
      animationType='fade'
      statusBarTranslucent
    >
      <View style={styles.container}>
        <View style={[styles.content, {width: !calendarVisible ? '50%' : '80%'}]}>
          <View style={[styles.contentView, {flex: !calendarVisible ? 1 : 0.55}]}>
            <View style={styles.titleView}>
              <Text style={styles.titleText}>
                Date and Time Selection
              </Text>
            </View>

            <View style={styles.dateSelectionView}>
              <DateTimeSelection
                onDateButtonSelected={showCalendar}
                filterType={FilterTypes.From}
                highlight={currentDateType === FilterTypes.From}
                date={fromDate}
                onTimeSelected={(time: Date): void => setFromTime(time)}
                initialTime={fromTime}
              />

              <DateTimeSelection
                onDateButtonSelected={showCalendar}
                title="To"
                filterType={FilterTypes.To}
                highlight={currentDateType === FilterTypes.To}
                date={toDate}
                onTimeSelected={(time: Date): void => setToTime(time)}
                initialTime={toTime}
              />
            </View>

            <View style={styles.applyButtonView}>
              <TouchableOpacity
                onPress={onApplySelected}
                style={styles.button}
                disabled={!isValid()}
              >
                <LinearGradient
                  colors={isValid() ? Colors.LOGIN().active : Colors.LOGIN().inactive}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Apply
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>

              <Text style={styles.errorText}>
                {!isValid() ? ERROR_MESSAGE : ''}
              </Text>
            </View>
          </View>

          {calendarVisible ? (
            <View style={styles.calendarView}>
              <DatePicker
                options={{
                  headerFont: Fonts.JudgeMedium,
                  defaultFont: Fonts.JudgeMedium,
                  backgroundColor: Colors.PRIMARY(),
                  textHeaderColor: Colors.APP.teal,
                  textDefaultColor: 'white',
                  selectedTextColor: Colors.APP.teal,
                  mainColor: 'white',
                  textSecondaryColor: 'white',
                  borderColor: Colors.APP.teal,
                  textFontSize: RFValue(9),
                  textHeaderFontSize: RFValue(9),
                }}
                current={formatDateToModalString(
                  getDefaultDateRange().start,
                )}
                selected="2020-07-23"
                onDateChange={onDateSelected}
                mode="calendar"
                style={{
                  height: '90%',
                  width: '70%',
                }}
              />
            </View>
          ) : null}

        <ExitButton
          onPressed={onModalClosed}
        />
        </View>
      </View>
    </Modal>
  ));
}

/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import Logger from '../Logger/Logger';
import LocalStorageService from '../Services/LocalStorageService';
import Roles from '../Types/Roles';

const logger = Logger.Create('RoleRepository');

/**
 * Role Based Feature accessibility.
 */
export class RoleRepository
{
  /**
   * The current role features.
   */
  public role: Roles = Roles.Operator;

  protected roleStorage: LocalStorageService<Roles> = new LocalStorageService<Roles>('role');

  /**
   * Returns the role associated with this account.
   */
  public getRole(): Roles
  {
    return this.role;
  }

  /**
   * Updates the role.
   * @param role The role.
   */
  public async updateRole(role?: Roles | Roles[]): Promise<void>
  {
    if (role === undefined)
    {
      return;
    }

    this.role = this.getRoleFromRoles(role);
    await this.roleStorage.save(this.role);
  }

  /**
   * Returns the role.
   * @param role The role or roles.
   * @returns The role.
   */
  public getRoleFromRoles(role: Roles | Roles[]): Roles
  {
    if (!Array.isArray(role))
    {
      return role;
    }

    if (role.includes(Roles.Admin))
    {
      return Roles.Admin;
    }

    if (role.includes(Roles.Manager))
    {
      return Roles.Manager;
    }

    if (role.includes(Roles.Supervisor))
    {
      return Roles.Supervisor;
    }

    return Roles.Operator;
  }

  /**
   * Loads the role features.
   */
  public load(): void
  {
    logger.info('Loading role features...');
  }

  /**
   * Retrieves the role from storage.
   */
  public async retrieveRoleFromStorage(): Promise<void>
  {
    const value = await this.roleStorage.load();
    this.role = value ?? Roles.Operator;
    this.load();
  }

  /**
   * Clear the repository.
   */
  public clearAll(): void
  {
    this.role = Roles.Operator;
    this.roleStorage.clear();
  }
}

decorate(RoleRepository, {
  role: observable,
});

export const roleRepository = new RoleRepository();

/**
 * The Promise Response model.
 */
export interface PromiseResponse<T = void>
{
  /**
   * Success flag.
   */
  success: boolean;

  /**
   * The promise failure reason.
   */
  reason: string;

  /**
   * The promise data.
   */
  data?: T;
};

/**
 * A success promise.
 * @param data The data.
 * @returns Promise.
 */
export function PromiseSuccess<T = void>(data?: T): PromiseResponse<T>
{
  return {
    success: true,
    reason: '',
    data,
  }
};

/**
 * A failed promise.
 * @param reason Failure reason.
 * @returns Promise.
 */
export function PromiseFail<T>(reason: string): PromiseResponse<T>
{
  return {
    success: false,
    reason: reason,
  }
};

import { useObserver } from 'mobx-react-lite';
import React, { memo, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Image, ScrollView, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { responsiveScreenRepository } from '../../../Domain/Repositories/ResponsiveScreenRepository';
import { RFValue } from 'react-native-responsive-fontsize';
import Fonts from '../../../Domain/Types/Fonts';
import Icons from '../../Assets/Icons';

interface LogViewProps
{
  /**
   * The logs.
   */
  logs?: string[];

  /**
   * Callback when the clear logs was pressed.
   */
  onClearLogsPressed?: () => void;
}

interface LogScrollViewProps
{
  /**
   * The logs.
   */
  logs?: string[];

  /**
   * Scroll view style.
   */
  scrollViewStyle?: StyleProp<ViewStyle>;

  /**
   * Text style.
   */
  textStyle?: StyleProp<TextStyle>;
}

const MemoizedScrollView = memo(({
  logs = [],
  scrollViewStyle,
  textStyle,
}: LogScrollViewProps) => {
  return (
    <ScrollView style={scrollViewStyle}>
      {logs.map((x, index) => (
        <Text key={index} style={textStyle}>
          {x}
        </Text>
      ))}
    </ScrollView>
  );
}, (prevProps, nextProps) => {
  return prevProps.logs === nextProps.logs && prevProps.scrollViewStyle === nextProps.scrollViewStyle && prevProps.textStyle === nextProps.textStyle;
});

/**
 * Renders the LogView.
 */
export default function LogView({
  logs = [],
  onClearLogsPressed,
}: LogViewProps): JSX.Element
{
  const styles = useMemo(() => StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
    },
    titleContainer: {
      flex: 1,
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      borderBottomWidth: RFValue(1),
      borderBottomColor: 'white',
      paddingLeft: '5%',
    },
    titleText: {
      fontSize: RFValue(8),
      letterSpacing: RFValue(0.3),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'center',
    },
    exitButtonContainer: {
      height: '100%',
      width: undefined,
      aspectRatio: 1,
    },
    exitButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    exitButtonIcon: {
      width: '60%',
      height: undefined,
      aspectRatio: 1,
    },
    logContainer: {
      flex: 5,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: '5%',
    },
    logContentContainer: {
      width: '100%',
      height: '100%',
    },
    logScrollView: {
      width: '100%',
      height: '100%',
    },
    logText: {
      fontSize: RFValue(7),
      letterSpacing: RFValue(0.3),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'left',
      paddingRight: '5%',
      paddingVertical: '4%',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.titleText}>
          Activity Logs
        </Text>

        <View style={styles.exitButtonContainer}>
          <TouchableOpacity
            style={styles.exitButton}
            onPress={onClearLogsPressed}
          >
            <Image
              source={Icons.Exit}
              style={styles.exitButtonIcon}
              resizeMode='contain'
            />
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.logContainer}>
        <View style={styles.logContentContainer}>
          <MemoizedScrollView
            logs={logs}
            scrollViewStyle={styles.logScrollView}
            textStyle={styles.logText}
          />
        </View>
      </View>
    </View>
  ));
}
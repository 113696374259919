import { useEffect } from 'react';
import { Platform } from 'react-native';
import Logger from '../Logger/Logger';

const logger = Logger.Create('useDetectKeyPress');

/**
 * The keydown event interface
 */
interface KeydownInterface
{
  /**
   * The detected key.
   */
  key: string;
}

/**
 * Hook to listen and respond to specific key presses.
 */
const useDetectKeyPress = (key: string[], onPressDetected?: () => void): void =>
{
  const onKeyPressDetected = (event: KeydownInterface): void =>
  {
    if (onPressDetected !== undefined)
    {
      if (key.length === 0)
      {
        logger.fatal('Specify a key to detect!');
        return;
      }

      if (key.includes(event.key.toLowerCase()))
      {
        onPressDetected();
      }
    }
  };

  useEffect(() =>
  {
    if (Platform.OS === 'web' && typeof window !== undefined)
    {
      window.addEventListener('keydown', onKeyPressDetected);
      return ((): void => window.removeEventListener('keydown', onKeyPressDetected));
    }

    return undefined;
  }, []);
};

export default useDetectKeyPress;
import { useObserver } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { StyleSheet, View, Text, TextInput } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { responsiveScreenRepository } from '../../../Domain/Repositories/ResponsiveScreenRepository';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';

interface TextInputWithLabelProps
{
  /**
   * The title.
   */
  title?: string;

  /**
   * The value.
   */
  value?: string;

  /**
   * Callback when the text input value changes.
   */
  onChangeText?: (text: string) => void;
}

/**
 * Renders the Text Input With Label.
 */
export default function TextInputWithLabel({
  title = '',
  value = '',
  onChangeText,
}: TextInputWithLabelProps): JSX.Element
{
  const styles = useMemo(() => StyleSheet.create({
    container: {
      flexDirection: 'row',
      width: '100%',
      height: '100%',
    },
    titleContainer: {
      flex: 2,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    titleLabel: {
      color: 'white',
      fontSize: RFValue(9),
      letterSpacing: RFValue(0.3),
      fontFamily: Fonts.JudgeMedium,
    },
    textInputContainer: {
      flex: 4,
      height: '100%',
      backgroundColor: Colors.BLACK(0.25),
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    textInput: {
      color: 'white',
      fontSize: RFValue(8),
      letterSpacing: RFValue(0.3),
      fontFamily: Fonts.JudgeMedium,
      width: '90%',
      marginLeft: '5%',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.titleLabel}>
          {title}
        </Text>
      </View>

      <View style={styles.textInputContainer}>
        <TextInput
          style={styles.textInput}
          placeholder='Required Field'
          placeholderTextColor='white'
          value={value}
          onChangeText={onChangeText}
        />
      </View>
    </View>
  ));
}
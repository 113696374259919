export const PRIMARY_BACKGROUND_COLOR = '#561CDC';
export const ITEM_BACKGROUND_COLOR = 'white';
export const BUTTON_COLOR = '#00FFA0';
export const BUTTON_TEXT_COLOR = PRIMARY_BACKGROUND_COLOR;
export const TEXT_COLOR = PRIMARY_BACKGROUND_COLOR;
export const DISABLED_BUTTON_COLOR = 'gray';

interface LoginColor
{
  active: string[];
  inactive: string[];
}

const APP = {
  background: ['black', '#0A0050', '#0A0050', '#0A0050'],
  red: "rgb(229, 1, 56)",
  green: "rgb(55, 179, 74)",
  blue: "rgb(59, 100, 148)",
  darkerBlue: "rgb(16, 17, 45)",
  darkBlue: "rgb(10, 0, 80)",
  teal: "rgb(19, 198, 222)",
  gold: "rgb(213, 187, 120)",
};

const APP_OPACITY = (opacity = 1) =>
{
  return {
    background: [
      `rgba(0, 0, 0, ${opacity})`,
      `rgba(10, 0, 80, ${opacity})`,
      `rgba(10, 0, 80, ${opacity})`,
      `rgba(10, 0, 80, ${opacity})`,
    ],
    red: `rgba(229, 1, 56, ${opacity})`,
    green: `rgba(55, 179, 74, ${opacity})`,
    blue: `rgba(59, 100, 148, ${opacity})`,
    darkerBlue: `rgba(16, 17, 45, ${opacity})`,
    darkBlue: `rgba(10, 0, 80, ${opacity})`,
    teal: `rgba(19, 198, 222, ${opacity})`,
    gold: `rgba(213, 187, 120, ${opacity})`,
  }
};

const VENUE_SCREEN = {
  outlineGradient: ['transparent', APP.teal, APP.teal]
};

const ERROR_RED = '#FC6464';

const PAUSED_BLUE = '#53AEC9';

const PRIMARY = (opacity = 1): string => `rgba(36, 36, 36, ${opacity})`;

const BLACK = (opacity = 1): string => `rgba(0, 0, 0, ${opacity})`;

const PRIMARY_TITLE = (opacity = 1): string => `rgba(34, 34, 34, ${opacity})`;

// if returning an object containing an array that will contain duplicate,
// we have to wrap it in a function and return it as typeof any.
// RN-Bob merges duplicate strings otherwise. idiot.
const MENU_ITEM = (): any =>
{
  return ({
    mainMenuItem: 'rgb(51, 51, 51)',
    activeSubMenu: ['#8A150C', '#FC6464'],
    inactiveSubMenu: ['transparent', 'transparent'],
  });
};

const LOGIN = (): LoginColor => {
  return ({
    active: [APP_OPACITY().teal, APP_OPACITY(0.8).teal],
    inactive: [APP_OPACITY(0.5).teal, APP_OPACITY(0.5).teal],
  });
};

const LOGIN_BACKGROUND = '#222222';

const CENTER_COLUMN_ITEM = (showBackground: boolean): string => (showBackground ? '#383737' : 'transparent');

const ACTIVE = (isActive: boolean): string => (isActive ? '#59D398' : ERROR_RED);

const ACTIVE_HEADER = (isActive: boolean): string => (isActive ? '#2F2F2F' : '#403D3D');
const HEADER_COLORS = (isKickedColumn: boolean): string => (isKickedColumn
  ? ERROR_RED : PAUSED_BLUE);

const DROPDOWN = {
  icon: 'white',
  border: '#464646',
  unselected: '#393838',
  selected: APP.teal,
};

const LIST = {
  header: '#333333',
};

const PAGINATION = {
  active: ERROR_RED,
  inactive: 'rgba(252, 100, 100, 0.25)',
};

const BUTTON = {
  positive: ['#59D398', '#379869'],
  negative: ['#FC6464', '#8A150C'],
};

/**
 * The Cube Colors.
 */
export const Colors = {
  PRIMARY_TITLE,
  MENU_ITEM,
  LOGIN,
  LOGIN_BACKGROUND,
  CENTER_COLUMN_ITEM,
  ACTIVE,
  ACTIVE_HEADER,
  HEADER_COLORS,
  DROPDOWN,
  LIST,
  PAGINATION,
  BUTTON,
  PRIMARY,
  BLACK,
  ERROR_RED,
  PAUSED_BLUE,
  APP,
  APP_OPACITY,
  VENUE_SCREEN,
}
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import FontHandler from './Domain/Handlers/FontHandler';
import Root from './Root';

/**
 * Application Entrypoint.
 */
export default function App(): JSX.Element
{
  const [loaded, setLoaded] = useState(false);

  useEffect(() =>
  {
    (async (): Promise<void> =>
    {
      setLoaded(false);
      await FontHandler.initialise();
      setLoaded(true);
    })();
  }, []);

  if (!loaded)
  {
    return <View />;
  }

  return <Root />;
}

/* eslint-disable import/prefer-default-export */

import Environment from '../Domain/Models/Environment';
import OidConfig from '../Domain/Models/OidConfig';

const Local: Environment = {
  apiBase: 'https://host.docker.internal:5051',
  jwtAuthorityBaseUrl: 'https://host.docker.internal:5161/connect/token',
  clientSecret: 'dev123',
};

const Staging: Environment = {
  apiBase: window.location.origin,
  jwtAuthorityBaseUrl: 'https://up-sso-staging.azurewebsites.net/connect/token',
  clientSecret: 'AtWCTrMkJDg7TwhV3Ry8W0xzg3mQe3AW',
};

const Production: Environment = {
  apiBase: window.location.origin,
  jwtAuthorityBaseUrl: 'https://up-sso.urbanplaygroundtech.co.uk/connect/token',
  clientSecret: 'DwskmDxQJh7SzYqf7XloLoTGW2rvnPM1',
};

/**
 * Returns the environment configuration based on the config.
 * @returns The environment.
 */
export const getEnvironment = (): Environment =>
{
  switch (window.config.REACT_ENVIRONMENT)
  {
    case 'Staging':
      return Staging;
    case 'Production':
      return Production;
    case 'Local':
    default:
      return Local;
  }
};

/**
 * Gets the OID config.
 * @returns OID Config.
 */
export const getOidConfig = (): OidConfig =>
{
  const env = getEnvironment();

  return {
    authenticationUrl: env.jwtAuthorityBaseUrl,
    clientSecret: env.clientSecret,
    clientId: 'UrbanPlayground.AuditingApp',
    grantType: 'password',
    scope: 'UrbanPlayground.Auditing.Api',
  };
};
